html,
body,
#root,
#parent {
  position: relative;
  width: 100%;
  height: 100%;
}
.MuiPaper-root {
  background-color: rgba(255, 255, 255, 0) !important;
  box-shadow: none !important;
}

#Main {
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

a {
  text-decoration: none;
  color: #000;
}

.btn-RSVP {
  background-color: #fff5ee !important;
  font-size: 1.5rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  left: 40%;
}

.RSVP {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.wedding,
.accom,
.reg,
.story {
  font-family: 'Montserrat', sans-serif!important;
  margin: auto;
  padding: 0px;
  text-align: center;
  font-size: 1.1rem;
}

.wedding a {
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: underline;
  color: #000;
}

.wedding h2 {
  font-size: 1.6rem;
  margin-top: 8px;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;
}

.wedding h2 hr {
  width: 6%;
  height: 1px;
  position: relative;
  bottom: 7px;
  border: none;
  margin-bottom: 0px;
  background-color: #dad1cb;
}

.wedding h3 {
  font-size: 1.3rem;
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
}

.wedding h3 hr {
  width: 6%;
  height: 1px;
  position: relative;
  bottom: 7px;
  border: none;
  margin-bottom: 0px;
  background-color: #dad1cb;
}

.accom a {
  font-size: 1.6rem;
  text-decoration: underline;
  color: #000;
}

.accom .code {
  font-size: 1rem;
}

#reception,
#ceremony {
  font-size: 0.9rem;
}

.intro {
  font-size: 1.2rem;
}

.intro hr {
  width: 6%;
  height: 1px;
  position: relative;
  bottom: 7px;
  border: none;
  margin-bottom: 0px;
  background-color: #dad1cb;
}

.reg a {
  font-size: 1.6rem;
  text-decoration: underline;
  color: #000;
}

.story a {
  text-decoration: underline;
  color: #000;
}
